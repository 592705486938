/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function($) {

    /* CookieBanner aktivieren */
    //netCookie.init();

    /* just for styleable scrollbars -.- */

    $(".imageText .scroll, .textProduct .scroll, .formImage.white .wpcf7").overlayScrollbars({
        className       : "os-theme-dark",
        sizeAutoCapable : true,
        autoUpdate      : true,
        overflowBehavior : {
            x : "hidden",
            y : "scroll"
        },
    });

});
