jQuery(function($) {

    /* nextPage animations */

    if ($('.nextPage.scroll').length) {

        let scroll = gsap.timeline({
            scrollTrigger: {
                trigger: ".nextPage.scroll",
                toggleActions: "play pause play pause",
                start: "top bottom", // when the top of the trigger hits the top of the viewport
                end: "bottom bottom", // end after scrolling 500px beyond the start
                scrub: true
            }
        });

        scroll.from(".nextPage.scroll a", {duration: 1, scale: 3, y: -100, autoAlpha: 0})
              .from(".nextPage span", { duration: .5, y: 10, autoAlpha: 0});

    }

    if ($('.nextPage.static').length) {

        var next = $(".nextPage");

        gsap.set(next.find("a"), { scale: 3, y: -100, autoAlpha: 0 })
        gsap.set(next.find("span"), { y: 10, autoAlpha: 0 })

        let intro = gsap.timeline({ duration: 0.2 });

        intro
            .to(next.find("a"), { delay: 3, duration: 1, scale: 1, y: 0, autoAlpha: 1 })
            .to(next.find("span"), { y: 0, autoAlpha: 1 })
            .to(next.find("span"), { delay: 0.5, y: 10, autoAlpha: 0, onComplete: function() {

                next.on("mouseover", "a", function () {
                    nextHoverTl.play();
                }).on("mouseout", function () {
                    nextHoverTl.reverse();
                });

            } });

        let nextHoverTl = gsap.timeline({ paused: true });

        nextHoverTl.to(next.find("span"), {  duration: 0.2,  y: 0, autoAlpha: 1 });
    }

});
