jQuery(function($) {

    var overlay = $('.fancybox-shop-overlay');

    //if (typeof cookieName !== 'undefined' && !$.cookie(cookieName) && overlay.length) {

    if (overlay.length) {

        // Get a cookie
        var cookieVal = docCookies.getItem('sandwich');

        if( cookieVal != 'turkey-with-tomato-and-mayo' ) {
            $.fancybox.open({
                src: '.fancybox-shop-overlay',
                type: 'inline',
                touch: false,
                baseClass: 'fancybox-shop',
                clickSlide : false,
                modal: true,
                afterClose: function (instance, slide) {
                    // Set a cookie
                    docCookies.setItem('sandwich', 'turkey-with-tomato-and-mayo', Infinity, '/');
                }
            });
        }
    }

    $(".closeOverlay").on('click', function(e){
        e.preventDefault();
        if( !$(this).is('[disabled=disabled]')) {
            $.fancybox.close();
        }
    });

    $("#age").on('click', function(){
        if (this.checked) {
            $(".closeOverlay").removeAttr("disabled");
            $(".closeOverlay").removeClass("disabled");
        } else {
            $(".closeOverlay").attr("disabled", true);
            $(".closeOverlay").addClass("disabled");
        }
    });

});
