jQuery(function($) {

    /* textProduct page animations */

    // create a timeline for each product overlay and assign that timeline as the "animation" of each product

    $(".textProduct").each(function(){
        var overlay = $(this).find('.overlay');
        var li = overlay.find('li');
        var closeTriggerLeft = overlay.find('.close-trigger-bar.left');
        var closeTriggerRight = overlay.find('.close-trigger-bar.right');
        var tl = gsap.timeline({ reversed:true, ease: "power3.inOut"});

        tl.from(overlay, {duration: .4, autoAlpha: 0, y: 40, rotate: -10, scale: .5,})
          .from(li, {duration: .3, opacity: 0, y: 5, stagger: .1}, "-=.5")
          .from(closeTriggerLeft, {duration: .4, opacity: 0, x: -20, y: -20, ease: "back.out(1.7)"}, "<.5")
          .from(closeTriggerRight, {duration: .4, opacity: 0, x: 20, y: -20, ease: "back.out(1.7)"}, "-=.3");

        this.animation = tl;
    })

    $(".textProduct").on('click', '.bottle, .showDetails, .close-trigger', function(event){

        event.preventDefault();

        var element = event.delegateTarget;
        element.animation.reversed(!element.animation.reversed());

    });

});
