jQuery(function($) {

    /* front-page morph headline */

    gsap.registerPlugin(MorphSVGPlugin);

    gsap.to("#letter-one-normal", {duration: 1.5, delay: 1, morphSVG: {
        shape: "#letter-one-italic",
        type: "rotational"
    }});

    gsap.to("#letter-two-normal", {duration: 1.5, delay: 1.25, morphSVG: {
        shape: "#letter-two-italic",
        type: "rotational"
    }});

    gsap.to("#letter-three-normal", {duration: 1.5, delay: 1.5, morphSVG: {
        shape: "#letter-three-italic",
        type: "rotational"
    }});

    gsap.to("#letter-four-normal", {duration: 1.5, delay: 1.75, morphSVG: {
        shape: "#letter-four-italic",
        type: "rotational"
    }});

    gsap.to("#dot-normal", {duration: 1.5, delay: 2, morphSVG: {
        shape: "#dot-italic",
        type: "rotational"
    }});

});
