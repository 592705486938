/* formImage animations */

let formImage = gsap.timeline();

function formImageInit() {
    formImage.from(".formImage .image", {autoAlpha: 0, scale: 0.8, y: 100, duration: "1"}, "-=1.6")
             .from(".formImage .inner", {autoAlpha: 0, scale: 0.8, y: 100, duration: "1"}, "<")
             .from(".formImage .wpcf7-form", {autoAlpha: 0, scale: 0.8, y: 100, duration: "1"}, "<")
             .from(".formImage .fieldWrapper", {duration: .7, autoAlpha: 0, y:10, stagger: .2}, "<");
}

window.addEventListener("load", function(event) {
    formImageInit(); // do stuff
});
