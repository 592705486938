/**
 * No changes needed here
 */

jQuery(function($) {

    /* scroll trigger helper function */

    function matchMediaOrNot(vars) {
      let queries = [],
          copy = {},
          isMatching,
          notMatchingFunc = vars.none,
          check = () => {
            let i = queries.length;
            while (i--) {
              if (queries[i].matches) {
                isMatching || ScrollTrigger.revert(false, "none");
                isMatching = true;
                return;
              }
            }
            isMatching = false;
            return notMatchingFunc();
          },
          wrap = func => () => {
            check();
            let result = func();
            return () => check() || result;
          },
          p;
      for (p in vars) {
        (p !== "none") && queries.push(window.matchMedia(p));
        copy[p] = wrap(vars[p]);
      }
      ScrollTrigger.matchMedia(copy);
      check();
    }

    /* horizontal scrolling panels */

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    ScrollTrigger.saveStyles("#container, .imageText, .textProduct, .history, figure, img");

    matchMediaOrNot({

        "(min-width: 1024px) and (min-aspect-ratio: 4/3) and (max-aspect-ratio: 2/1)": function() {
            if( $('#container').children().length > 1 ) {
                document.documentElement.classList.add("gsap");
                var end = ($('#container').children().length - 1) * innerHeight;

                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#container",
                        start: "top top",
                        end: "+=" + end,
                        scrub: true,
                        pin: true,
                        anticipatePin: 1
                    }
                });

                let slides = gsap.utils.toArray($("#container").children());

                slides.shift(); // remove first slide, because it should be the start slide

                var scrollTriggerTimeline = gsap.timeline();

                slides.forEach((slide, i) => {
                    tl.from(slide, { xPercent: 100, scale: 0.9 }).addLabel(slide.id);

                    var y = 100;
                    gsap.utils.toArray(slide.querySelectorAll('figure')).forEach((figure, i) => {
                        if( i == 1 ) { y = -100; }
                        tl.from(figure, {opacity: 0, scale: 0.8, y: y}, "<");
                        tl.from(figure.querySelector('img'), {opacity: 0, scale: 1.8}, "<");
                    });

                    tl.to(slide.querySelector('.one .bg'), {xPercent: 50}, "<");
                    tl.to(slide.querySelector('.two .bg'), {xPercent: -50}, "<");
                });

                var url = window.location.href.split('#')[0];
                var hash = window.location.href.split('#')[1];

                /* on button with hash click scroll to section */

                $("a.button[href*='#']").each(function(e){

                    var href = $(this).attr('href');
                    var target = href.split('#')[1];

                    if(href.indexOf(url) == 0) {
                        $(this).on('click', function(e){
                            e.preventDefault();
                            history.replaceState(null, '', '#' + target);
                            gsap.to(window, {scrollTo: $('#' + target).index() * innerHeight});
                        });
                    }
                });

                $(".next").each(function(e){

                    console.log($(this).parent().parent().index());

                    $(this).on('click', function(e){
                        console.log('click');
                        gsap.to(window, {scrollTo: ($(this).parent().parent().index() + 1) * innerHeight});
                    });
                });

                /* on page load scroll to section */

                if( typeof hash !== "undefined" ) {
                    gsap.to(window, {scrollTo: $('#' + hash).index() * innerHeight})
                }
            }
        },

        "none": function() {
            if( $('#container').children().length > 0 ) {
                document.documentElement.classList.remove("gsap");
                $("a.button[href*='#']").off('click');
            }
        },

    });

});
