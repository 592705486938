jQuery(function($) {
    if ($('.history').length) {

        const historyYears = new Swiper('.history .historyYears', {
            slidesPerView: 'auto',
            spaceBetween: 150,
            centeredSlides: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '.history .swiper-button-next',
                prevEl: '.history .swiper-button-prev',
            },
            breakpoints: {
                600: {
                    slidesPerView: 3,
                    spaceBetween: 0
                }
            }
        });

        const historyImages = new Swiper('.history .historyImages', {
            spaceBetween: 0
        });

        const historyEntries = new Swiper('.history .historyContents', {
            spaceBetween: 10,
            simulateTouch: false,
            shortSwipes: false,
            longSwipes: false
        });

        historyYears.on('slideChange', function() {
            let index_currentSlide = historyYears.realIndex;
            historyImages.slideTo(index_currentSlide, 500, false);
            historyEntries.slideTo(index_currentSlide, 500, false);
        });

        historyImages.on('slideChange', function() {
            let index_currentSlide = historyImages.realIndex;
            historyYears.slideTo(index_currentSlide, 500, false);
            historyEntries.slideTo(index_currentSlide, 500, false);
        });

    }
});
